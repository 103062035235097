<template>
  <div class="card-offered">
    <card-info
      :item="offered"
      :show-year="true"
    />
    <div class="card-actions">
      <div class="mb-8">
        <div class="action-title">
          {{ mountTranslate('total_value') }}
        </div>
        <div class="total-value">
          {{ formatMoney(offered.total_value_with_aditional) }}
        </div>
      </div>
      <div class="action-form d-flex justify-space-between mb-8">
        <div class="mr-4">
          <div class="text-unit-value mb-2">
            {{ mountTranslate('unit_value') }}
          </div>
          <div class="font-weight-bold">
            {{ formatMoney(offered.unit_value_with_aditional) }}
          </div>
        </div>
      </div>

      <div class="button-actions">
        <rectoplus-button-outlined
          class="add-cart-button"
          :text="mountTranslate('buttons.add_cart')"
          @click="addOfferToCart(false)"
        />
        <rectoplus-button
          class="buy-button"
          :text="mountTranslate('buttons.buy_credit')"
          @click="addOfferToCart(true)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';
import RectoplusButtonOutlined from '@/components/buttons/RectoplusButtonOutlined.vue';
import formatMoney from '@/mixins/formatMoney';
import CardInfo from '@/components/cards/offered/CardInfo.vue';

export default {
  name: 'CardOffered',
  components: {
    RectoplusButtonOutlined,
    RectoplusButton,
    CardInfo,
  },
  mixins: [formatMoney],
  props: {
    offered: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters([
      'getCartItem',
    ]),
  },
  methods: {
    ...mapActions([
      'addToCart',
    ]),
    mountTranslate(path, value) {
      return this.$t(`components.offered.card_offered.${path}`, {
        value,
      });
    },

    addOfferToCart(directPurchase) {
      this.addToCart({
        credit_offered_id: this.offered.id,
      });
      this.$router.push({ name: directPurchase ? 'checkout' : 'home' });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-offered {
  display: flex;
  justify-content: space-between;
  background: $card_offer;
  border-radius: 10px;
  padding: 38px;

  .card-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;

    .action-title {
      font-size: 0.875em;
      color: $color_gray;
    }

    .total-value {
      font-size: 1.2em;
      color: $color_dark;
      font-weight: bold;
    }

    .button-actions {
      .add-cart-button {
        width: 100%;
        height: 56px;
        margin-bottom: 20px;
      }

      .buy-button {
        width: 100%;
        height: 56px;
      }
    }

    .action-form {
      .field-quantity {
        background: $dashboard_background;
        width: 100%;
        max-width: 150px;
        border-radius: 10px;
        padding: 4px;
        text-align: center;
        outline: none;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .text-quantity {
        margin-bottom: 4px;
      }

      .error-message {
        color: $message_invalid;
        font-size: 0.8em;
        margin-top: 4px;
      }

      .text-quantity,
      .text-unit-value {
        font-size: 0.875em;
        color: $color_gray;
      }
    }
  }
}
</style>
